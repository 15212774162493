import React from 'react'
import c1 from './photo/c1.jpg'
export default function Achivement() {
  return (
    <div>
        <h1 className="title">My Achivement</h1>
        <div className="card-container">
            <div className="card">
                <img className="img" src={c1} width="200px" height="150px" alt='certificate' />
                <h1>Python Pandas certificate</h1>
                <div className='card-text'>
                <p>
                  The Python Pandas Basics Course is a free,
                   self-paced online course that teaches the fundamentals of Python Pandas,
                    a powerful library for data analysis and manipulation. The course is designed to be beginner-friendly and accessible to learners with no prior experience in Python or data analysis
                    </p>
                </div>
            </div>
            
            
        </div>
    </div>
  )
}
