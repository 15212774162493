import './App.css';
import Achivement from './component/Achivement';
import Home from './component/Home';
import Navbar from './component/Navbar';
import { BrowserRouter as Router ,Routes ,Route } from "react-router-dom";
import Project from './component/Project';
import Footer from './component/Footer';
import Contact from './component/Contact';
function App() {
  return (
    <div >
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' Component={Home } />
          <Route path='/Achivement' Component={Achivement} />
          <Route path='/Project' Component={Project} />
          <Route path='/Contact' Component={Contact} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
