import React from 'react'
import simpcal from './photo/simpcal.jpg'
export default function Project() {
  return (
    <div>
        <h1 className="title">My Projects</h1>
        <div className="project-container">
            <div className="project">
                <div align="center" className="img">
                    <img width="200px" height="350px" src={simpcal} alt="calculator" />
                    <h2 className="card-title">Simple Calculator</h2>
                    <p className="project-text" >
                        This simple GUI calculator is designed to perform basic arithmetic operations such as addition,
                         subtraction, multiplication, and division. It is built using Python's tkinter library,
                        which provides a simple and intuitive way to create graphical user interfaces.</p>
                    <div align="center" className="button">
                        <button ><a href="https://drive.google.com/file/d/1jbxDbkIHEaFUo6_bPkfzLcu0DzbwYSn4/view?usp=sharing" download="calculator"><h3>Download Code</h3></a></button>
                    </div>
                </div>
                
            </div>
            <div className="project">
                <div align="center" className="img">
                    <img align="center" width="250px" height="350px" src={simpcal} alt="calculator" />
                    <h2 className="card-title">Simple Calculator</h2>
                    <p className="project-text" >
                        This simple GUI calculator is designed to perform basic arithmetic operations such as addition,
                         subtraction, multiplication, and division. It is built using Python's tkinter library,
                     which provides a simple and intuitive way to create graphical user interfaces.
                     </p>
                    <div align="center" className="button">
                        <button ><a href="/project/calculator.py" download="calculator"><h3>Download Code</h3></a></button>
                    </div>
                </div>
            </div>
            <div className="project">
                <div align="center" className="img">
                    <img align="center" width="250px" height="350px" src={simpcal} alt="calculator" />
                    <h2 className="card-title">Simple Calculator</h2>
                    <p className="project-text" >
                        This simple GUI calculator is designed to perform basic arithmetic operations such as addition,
                         subtraction, multiplication, and division. It is built using Python's tkinter library,
                     which provides a simple and intuitive way to create graphical user interfaces.</p>
                    <div align="center" className="button">
                        <button ><a href="/project/calculator.py" download="calculator"><h3>Download Code</h3></a></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
