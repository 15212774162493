import React from 'react'
import { useState } from 'react'

export default function Contact() {
  const [data,adddata] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
    date: ''
  })
  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return `${mm}/${dd}/${yyyy}`;
  };
  const handleSubmit = (e) => {
    const Date =getCurrentDate();
    const {
      name,
      phone,
      email,
      message,
      date
    } = data
    e.preventDefault();

    const options ={
      method : 'POST',
      Headers : {
        'Content-Type':'application/json'
      },
      body: JSON.stringify({
        name,
        phone,
        email,
        message,
        date: Date
      })
    }
    fetch('https://portfolio-363e9-default-rtdb.firebaseio.com/contact.json',options)
  }
  return (
    <div className='contact'>
      <div className='contact-text'>
        <h1><u>Contact</u></h1>
        <p>Get in touch with me!</p>
      </div>
      <div className='contact-form'>
        <form className='contact-form' method='POST'>
          <input 
          type="text" 
          id="name" 
          name="name" 
          placeholder='Name' 
          value={data.name}
          onChange={(e)=>{adddata({...data,name:e.target.value})}}
          />
          <input 
          type="tel" 
          id = "phone" 
          name='tel' 
          placeholder='Phone No.' 
          value={data.phone}
          onChange={(e)=>{adddata({...data,phone:e.target.value})}}
          />
          <input 
          type="email" 
          id="email" 
          name="email" 
          placeholder='Email' 
          value={data.email}
          onChange={(e)=>{adddata({...data,email:e.target.value})}}
          />
          <textarea 
          id="message" 
          name="message" 
          placeholder='Message'
          value={data.message}
          onChange={(e)=>{adddata({...data,message:e.target.value})}}
          />
          <button type="submit" value="Send" onClick={handleSubmit}  >Submit</button>
        </form>
      </div>
    </div>
  )
}
