import React from 'react'
import a from './photo/a.png'

export default function Home() {
  return (
    <div>
        <div className="container" id="home" >
                <div className="text" align="center">
                    <h1>I Am <span><u>Ayush Kumar</u></span></h1>
                    <p>My name is Ayush Kumar. 
                        currently pursuing a Bachelor's degree in Computer Applications. 
                        I possess skills in Python , SQL , HTML, CSS, and SQL.
                    </p>
                </div>
                <div className="image" align="center">
                    <img alt="ayush" src={a} width="80%" />
                </div>
            </div>
            <div className="container2">
                <h1 className="title" id="skills">My Programming Skills</h1>
                <div className="skills">
                    <div className="skill">
                        <h2>HTML</h2>
                        <p>I can create structured and semantic HTML code that is easy to read and understand.</p>
                    </div>
                    <div className="skill">
                        <h2>CSS</h2>
                        <p>I can style and layout web pages using CSS</p>
                    </div>
                    <div className="skill">
                        <h2>Python</h2>
                        <p>I have experience with Python ,Including data statical analysis </p>
                    </div>
                    <div className="skill">
                        <h2>SQL</h2>
                        <p>I have experience with SQl, I have used SQl for making database</p> 
                    </div>
                </div>
            </div>  
            
    </div>
  )
}
