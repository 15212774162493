import React, { useState } from 'react'
import a from './photo/a.png'
import { Link } from 'react-router-dom'

const Navbar = () => {
	const [isOpen, setIsOpen] = useState(false);
  
	const handleToggle = () => {
	  setIsOpen(!isOpen);
	};

  return (
    <div className="navbar" >
			<div className="logo">
				<img src={a} alt="logo" />
			</div>
		<div className="nav">
			<button  className='but' onClick={handleToggle}>
				<span className="bar"></span>
				<span className="bar2"></span>
				<span className="bar3"></span>
			</button>
			<ul className={`link ${isOpen ? 'open' : ''}`}>
				<div className='linkco'>
					<li><Link to="/">Home</Link></li>
					<li><Link to="/Achivement">Achivement</Link></li>
					<li><Link to="/Project">Projects</Link></li>
					<li><Link to="/Contact">Contact</Link></li>
				</div>
			</ul>
		</div>
    </div>
  )
};
export default Navbar;

