import React from 'react'

export default function Footer() {
  return (
    <div class="footer">
        <p>Ayush Kumar</p>
        <ul>
            <li>My name is Ayush Kumar. 
                currently pursuing a Bachelor's degree in Computer Applications. 
                I possess skills in Python , SQL , HTML, CSS, and SQL.
            </li>
        </ul>
        <ul>
            <li>+91 7838945961</li>
        </ul>
    </div> 
  )
}
